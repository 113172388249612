// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // server: "http://5.2.3.119:9980/api/"
  server: "http://5.0.32.154:8080/api/",
  documentoApi : "http://localhost:9980"

};
