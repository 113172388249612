import { Component, OnInit } from '@angular/core';
import { Filtro } from 'src/app/models/Filtro';
import Page from 'src/app/models/Page';
import { DocumentoModel } from 'src/app/models/documentoModel';
import { DocumentoService } from 'src/app/services/documento.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';




@Component({
  selector: 'app-gestion-documental',
  templateUrl: './gestion-documental.component.html',
  styleUrls: ['./gestion-documental.component.css']
})



export class GestionDocumentalComponent implements OnInit {

  termino : string = '';
  sort : number = 2;
  previousSort : number = 2;
  userOrden : boolean = false;

  dependFiltros : Filtro [] = [];
  rubroFiltros :  Filtro [] = [];
  fechaFiltros :  Filtro [] = [];
  numeroFiltros :  Filtro [] = [];

  filtrosAplicados : Filtro [] = [];

  opcionesDependencia : Filtro [] = [];
  opcionesRubro : Filtro [] = [];

  modal : NgbModalRef;

  page: Page<DocumentoModel>;
  pageNumber : number = 0;
  resultados : number = 0;
  public sm: boolean = false;


  readonly breakpoint$ = this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall])
                          .pipe(distinctUntilChanged());

  searchDisable : boolean = false;




  constructor(private docSrv : DocumentoService, private toastr: ToastrService,
    private route: ActivatedRoute, private router: Router,
    private spinner: NgxSpinnerService, private breakpointObserver: BreakpointObserver,
    private modalService: NgbModal) {
  }

  ngOnInit() {
    this.breakpoint$.subscribe(() =>
        this.breakpointChanged()
      );
    this.route.queryParams.subscribe(queryParam => {
      this.dependFiltros = queryParam.depend ? JSON.parse(queryParam.depend) : [];
      this.fechaFiltros = queryParam.fecha ? JSON.parse(queryParam.fecha) : [];
      this.rubroFiltros = queryParam.rubro ? JSON.parse(queryParam.rubro) : [];
      this.numeroFiltros = queryParam.numero ? JSON.parse(queryParam.numero) : [];
      this.pageNumber = queryParam.page ? queryParam.page : 0;
      this.termino = queryParam.termino ? queryParam.termino : '';
      this.sort = queryParam.sort ? parseInt(queryParam.sort) : 2;
      this.previousSort = this.previousSort ?  this.previousSort : 2;
      this.userOrden = this.userOrden || this.sort == 4 || this.sort == 5;
      this.searchDisable = queryParam.sd;
      this.ejecutarBusqueda();
      if(this.modal) this.modal.close();
    });
  }

  private breakpointChanged() {
    if(this.breakpointObserver.isMatched(Breakpoints.Small) || this.breakpointObserver.isMatched(Breakpoints.XSmall) || this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.sm = true;
    } else {
      this.sm = false;
      if(this.modal){
        this.modal.close();
      }
    }
  }

  openModal(content) {
    this.modal = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'sm'});
  }


  removerFiltro(filtroRemovido :Filtro){
    switch(filtroRemovido.tipo){
      case 'depend':
        let indexD = this.dependFiltros.findIndex(fil => fil.id === filtroRemovido.id && fil.operador === filtroRemovido.operador && fil.tipo === filtroRemovido.tipo);
        this.dependFiltros = this.dependFiltros.slice(0, indexD);
        this.rubroFiltros = [];
        this.userOrden = false;
        if (this.sort == 4 || this.sort == 5){
          if(this.previousSort == 4 || this.previousSort == 5){
            this.sort = this.termino ? 1 : 2;
          }else{
            this.sort = this.previousSort;
          }
        }
        break;
      case 'rubro':
        let indexR = this.rubroFiltros.findIndex(fil => fil.id === filtroRemovido.id && fil.operador === filtroRemovido.operador && fil.tipo === filtroRemovido.tipo);
        this.rubroFiltros = this.rubroFiltros.slice(0, indexR);
        this.userOrden = false;
        if (this.sort == 4 || this.sort == 5){
          if(this.previousSort == 4 || this.previousSort == 5){
            this.sort = this.termino ? 1 : 2;
          }else{
            this.sort = this.previousSort;
          }
        }
        break;
      case 'fecha':
        this.fechaFiltros = this.fechaFiltros.filter(fil =>
          !(fil.id === filtroRemovido.id && fil.operador === filtroRemovido.operador && fil.tipo === filtroRemovido.tipo)
        );
        break;
      case 'numero':
        this.numeroFiltros = this.numeroFiltros.filter(fil =>
          !(fil.id === filtroRemovido.id && fil.operador === filtroRemovido.operador && fil.tipo === filtroRemovido.tipo)
        );
        break;
    }
    this.pageNumber = 0;
    this.navidate();
  }



  aplicarFiltro(filtroAgregado :Filtro){
    switch(filtroAgregado.tipo){
      case 'depend':
        this.dependFiltros.push(filtroAgregado);
        break;
      case 'rubro':
        this.rubroFiltros.push(filtroAgregado);
        this.userOrden = filtroAgregado.ordenamiento == 'ORDEN';
        if(this.userOrden && !this.termino){
          this.previousSort = this.sort;
          this.sort = 4;
        }

        break;
      case 'fecha':
        this.fechaFiltros.push(filtroAgregado);
        break;
      case 'numero':
        this.numeroFiltros.push(filtroAgregado);
        break;
    }

    this.pageNumber = 0;
    this.navidate();
  }

  navidate() {
    let queryParam = {};
    queryParam['page'] = this.pageNumber;
    if (this.termino) {
      queryParam['termino'] = this.termino;
    }
    if(this.dependFiltros.length){
      queryParam['depend'] = JSON.stringify(this.dependFiltros);
    }
    if(this.fechaFiltros.length){
      queryParam['fecha'] = JSON.stringify(this.fechaFiltros);
    }
    if(this.rubroFiltros.length){
      queryParam['rubro'] = JSON.stringify(this.rubroFiltros);
    }
    if(this.numeroFiltros.length){
      queryParam['numero'] = JSON.stringify(this.numeroFiltros);
    }
    if(this.sort){
      queryParam['sort'] = this.sort;
    }
    if(this.searchDisable){
      queryParam['sd'] = this.searchDisable;
    }

    this.router.navigate(['/gestion-documental'], {queryParams : queryParam});
  }


  buscarTermino(term : string){
    this.fechaFiltros = [];
    this.dependFiltros = [];
    this.rubroFiltros = [];
    this.numeroFiltros = [];
    this.pageNumber = 0;
    this.termino = term;
    if(this.termino){
      this.sort = 1;
      this.previousSort = 1;
    }else {
      this.sort = 2;
      this.previousSort = 2;
    }
    this.userOrden = false;
    this.navidate();
  }

  resetFiltros(){
    this.fechaFiltros = [];
    this.dependFiltros = [];
    this.rubroFiltros = [];
    this.numeroFiltros = [];
    this.pageNumber = 0;
    this.userOrden = false;
    if(this.previousSort == 4 || this.previousSort == 5){
      this.sort = this.termino ? 1 : 2;
    }else{
      this.sort = this.previousSort;
    }

    this.navidate();
  }

  ejecutarBusqueda(){
    let query : Filtro [] = [];
    if(this.termino){
      query.push(new Filtro(this.termino, this.termino, 'terms' ,0, ':'));
    }
    let length = this.dependFiltros.length;
    if(length){
      query.push(this.dependFiltros[length -1])
    }
    length = this.rubroFiltros.length;
    if(length){
      query.push(this.rubroFiltros[length -1])
    }
    if(this.fechaFiltros.length){
      query = query.concat(this.fechaFiltros);
    }

    if(this.numeroFiltros.length){
      query = query.concat(this.numeroFiltros);
    }

    let queryString : string = query.
      map(criteria => {
        if(criteria.tipo === 'fecha'){
          criteria.id = criteria.id.split('/').join('');
          if(criteria.operador === '~') {
            let fechas : string [] = criteria.id.split('~');
            return `${criteria.tipo}>${fechas[0]},${criteria.tipo}<${fechas[1]}`;
          }
        }
        if(criteria.tipo === 'numero'){
          if(criteria.operador === '~') {
            let valores : string [] = criteria.id.split('~');
            return `num:${valores[0]},anio:${valores[1]}`;
          }
          if(criteria.operador === '>') {
            return `num:${criteria.id}`;
          }
          if(criteria.operador === '<') {
            return `anio:${criteria.id}`;
          }
        }
        if(criteria.tipo === 'terms'){
          let termino = encodeURIComponent(criteria.id);
          return `terms:${termino}`;
        }

        return  criteria.tipo + criteria.operador + criteria.id;
      }
      ).join(',');

    switch(this.sort){
      case 2:
        queryString = queryString.concat('&sort=desde,desc');
        break;
      case 3:
        queryString = queryString.concat('&sort=desde,asc');
        break;
      case 4:
        queryString = queryString.concat('&sort=orden,desc');
        break;
      case 5:
        queryString = queryString.concat('&sort=orden,asc');
        break;
    }

    this.spinner.show();
    Observable.forkJoin(this.docSrv.search(queryString, this.pageNumber), this.docSrv.getFilters(queryString)).
    subscribe(([docs, filt]) =>{
      //procesar lista de documentos
      this.page = docs as Page<DocumentoModel>;
      this.page.content.forEach(doc => {
        doc.fileUrl =  `${this.docSrv.urlApi}/api/documento/adjunto/${doc.id}`;
      });
      this.pageNumber = this.page.number;
      this.filtrosAplicados = this.dependFiltros.concat(this.rubroFiltros).concat(this.fechaFiltros).concat(this.numeroFiltros);
      this.resultados = this.page.totalElements;
      //procesar filtros
      let filtros = filt as Filtro[];
      this.opcionesDependencia = filtros.filter(item => item.tipo ==='DEPENDENCIA');
      this.opcionesRubro = filtros.filter(item => item.tipo ==='RUBRO' || item.tipo ==='SUBRUBRO');
    }, error => {
      this.toastr.error(error.detail ? error.detail : 'Sistama momentaneamente fuera de servicio. Reintente luego.' , error.title ? error.title : 'Error de Conexión');
      this.spinner.hide();
    }, () => {
      this.spinner.hide();
    });
  }

  newPage(page : number){
    this.pageNumber = page;
    this.navidate();
  }

  ordenar(option : number){
    this.previousSort =  this.sort;
    this.sort = option;
    this.pageNumber = 0;
    this.navidate();
  }

  getTotalFiltros():number {
    return this.rubroFiltros.length + this.dependFiltros.length + this.fechaFiltros.length + this.numeroFiltros.length;
  }
}
