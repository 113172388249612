import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../guia-organica/menu/menu.component';
import { MenuModel } from '../../models/MenuModel';


@Component({
  selector: 'pagina-anterior',
  templateUrl: './pagina-anterior.component.html',
  styleUrls: ['./pagina-anterior.component.css'],
})

export class PaginaAnteriorComponent implements OnInit {
  public idDependencia: number = 36; 
  
  constructor(public route: ActivatedRoute, public router: Router){
   
  }

  ngOnInit(){
    
  }

}
