import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MainService } from '../../services/main.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})

export class HomeComponent implements OnInit {

  constructor(public route: ActivatedRoute, public router: Router, public mainService: MainService){
  }
  public serverDown(){
    this.router.navigate(["/pages/server-down"]);
  }

  ngOnInit(){
    document.body.style.overflowX = 'scroll';
    //Detectar browser
    try{
      let browser: string[] = this.mainService.getBrowser();
      if(browser[0].toLowerCase()=="firefox" && Number.parseInt(browser[1]) < 40){
        this.router.navigate(["/pages/old-browser"]);
      }
    }catch(ex){

    }
  }

}
