import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { Filtro } from '../models/Filtro';
import { Observable } from 'rxjs';
import { DocumentoModel } from '../models/documentoModel';
import  Page  from '../models/Page';
import { ErrorResponse } from '../models/ErrorResponse';

@Injectable({
  providedIn: 'root'
})
export class DocumentoService {

  public urlApi : string;

  constructor(private http: HttpClient, appConfig: AppConfig) {
    this.urlApi = appConfig.getConfig('documentoApi', '');
  }

  getFilters(query: string) : Observable<Filtro[] | ErrorResponse> {
    const resourse = "/api/documento/search/filter";
    const url = `${this.urlApi}${resourse}?query=${query}`;
    return this.http.get<Filtro[]>(url);
  }

  search(query: string, page : number) :  Observable<Page<DocumentoModel> | ErrorResponse>{
    const resourse = "/api/documento/search";
    const url = `${this.urlApi}${resourse}?query=${query}&page=${page}`;
    return this.http.get<Page<DocumentoModel>>(url);
  }

  public getFile(docId : number) : Observable<Blob> {
    let path = `/api/documento/adjunto/${docId}`;

    return this.http.get(this.urlApi.concat(path), {responseType: "blob"});
  }

}
