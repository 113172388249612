import { Dependencia } from './Dependencia';
export class Tutorial {
    public titulo: string;
    public cuerpo: string;
    public urlExterno: string;
    public urlTexto: string; 
    public orden: number;
    public icon: string;
    
    public dependencia: Dependencia;

    constructor() {
   
    }


}

