import { Component, Input, ViewChild} from '@angular/core';
import { DocumentoModel } from '../../../../models/documentoModel';
import {DocumentoService}  from '../../../../services/documento.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorResponse } from 'src/app/models/ErrorResponse';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged} from 'rxjs/operators';


@Component({
  selector: 'item-busqueda',
  templateUrl: './item-busqueda.component.html',
  styleUrls: ['./item-busqueda.component.css']
})

/**
 * Por cada item resultado
*/
export class ItemBusquedaComponent{

  /******************************************************************************************************
   * Inputs && Outputs
  ******************************************************************************************************/
  @Input() documento: DocumentoModel;
  @Input() sm : boolean;

  /******************************************************************************************************
   * Variables
  ******************************************************************************************************/
  previewFile : Blob;
  height : number;
  readonly breakpoint$ = this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall])
                          .pipe(distinctUntilChanged());

  /******************************************************************************************************
   * Constructor && OnInit
  ******************************************************************************************************/
  constructor(private docSrv : DocumentoService, private toastr: ToastrService,
    private modalService: NgbModal, private breakpointObserver: BreakpointObserver) {
      this.sm = false;
  }

  ngOnInit() {
    this.breakpoint$.subscribe(() => {
      if(this.breakpointObserver.isMatched(Breakpoints.Small) || this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
        this.height = 500;
      } else {
        this.height = 700;
      }
    });
    this.documento.fecha = this.documento.fecha ? this.documento.fecha.split(" ")[0].replace('-','/').replace('-','/') : null;
  }


  /******************************************************************************************************
   * Funciones
  ******************************************************************************************************/
  copyUrl(tooltip){
    navigator.clipboard.writeText(this.documento.fileUrl).then(
      result => {
        if (tooltip.isOpen()) {
          tooltip.close();
        } else {
          tooltip.open();
        }
      }
    ).catch(e => console.error(e));
  }

  preVisualisar(modalContent){
    this.docSrv.getFile(this.documento.id).subscribe(
      response => {
        this.previewFile = response;
        this.modalService.open(modalContent, { size: 'lg' });
    }, (error) => {
      error.error.text().then(texto => {
        let errorRespo : ErrorResponse = JSON.parse(texto);
        this.toastr.error(errorRespo.title);
      });

    });
  }


  /******************************************************************************************************
   * Response BackEnd && Procesar Datos
  ******************************************************************************************************/
}
