import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Filtro } from 'src/app/models/Filtro';

@Component({
  selector: 'app-category-filter',
  templateUrl: './category-filter.component.html',
  styleUrls: ['./category-filter.component.css']
})
export class CategoryFilterComponent implements OnChanges {

  @Input() titulo! : string;
  @Input() opciones! : Filtro [];
  @Output() aplicarFiltroEvent = new EventEmitter<Filtro>();

  aux : Filtro [];
  show : boolean = false;

  constructor() { }

  ngOnChanges(changes : SimpleChanges) {
    this.show = false;
    this.filterOption();
  }


  selectOpcion(filtroSeleccionado : Filtro){
    filtroSeleccionado.tipo = filtroSeleccionado.tipo === 'DEPENDENCIA'? 'depend' : 'rubro';
    filtroSeleccionado.operador = ':';
    this.aplicarFiltroEvent.emit(filtroSeleccionado);
  }

  showMore(){
    this.show = !this.show;
    this.filterOption();
  }


  filterOption(){
    if(!this.show){
      this.aux = this.opciones.slice(0, 5)
    }else {
      this.aux = this.opciones;
    }
  }



}
