import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import { HomeComponent } from './home.component';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { MainModule } from '../main/main.module';
import { MenusComponent } from '../main/menus/menus.component';
import { ServiciosComponent } from '../main/servicios/servicios.component';
import { AvisosComponent } from '../main/avisos/avisos.component';
import { HomeInteresComponent } from './home-interes/home-interes.component';
// import { InteresComponent } from '../main/interes/interes.component';


const appRoutes: Routes = [
  { path: '', component: HomeComponent },
];


@NgModule({
  declarations: [
    HomeComponent,
    HomeInteresComponent,
    // InteresComponent
  ],
  imports: [
    CommonModule,
    NgbModule.forRoot(),
    RouterModule.forChild(appRoutes),
    FormsModule,
    HttpClientModule,
    MainModule,    
  ],
  exports: [
  ],
  providers: [],
  bootstrap: [HomeComponent],
})
export class HomeModule { }
