import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
// import { Http2ServerRequest } from 'http2';
// import { MockService } from '../mock/mock.service';
import { CacheModel } from '../models/cacheModel';
import { SelectorMatcher } from '@angular/compiler';
import { AppConfig } from '../app.config';


@Injectable()
export class DataService{

    public useCache: number = 0;
    caches: CacheModel[] = [];


    public URL_DEPENDENCIA_ID ='URL_DEPENDENCIA_ID';
    public URL_DEPENDENCIA_CODIGO ='URL_DEPENDENCIA_CODIGO';
    public URL_DEPENDENCIA_NIVEL = "URL_DEPENDENCIA_NIVEL";
    public URL_DEPENDENCIA_PARENT = "URL_DEPENDENCIA_PARENT";
    public URL_NOVEDADES_NUEVO = "URL_NOVEDADES_NUEVO";
    public URL_PERSONA_DEPENDENCIA_ID = "URL_PERSONA_DEPENDENCIA_ID";
    public URL_FIND_DEPENDENCIA = "URL_FIND_DEPENDENCIA";
    public URL_FIND_DEPENDENCIAPERSONA = "URL_FIND_DEPENDENCIA_PERSONA";
    public URL_FIND_AVISO = "URL_FIND_AVISO";
    public URL_TUTORIAL_ALL = "URL_TUTORIAL_ALL";
    public URL_WEB_SERVICIO_ALL = "URL_WEB_SERVICIO_ALL";
    public URL_WEB_PRINCIPAL = "URL_WEB_PRINCIPAL";
    public URL_WEB_LINKS = "URL_WEB_LINKS";
    public URL_WEB_LINKS_ORGANICA = "URL_WEB_LINKS_ORGANICA";
    public URL_FIND_PERSONA = "URL_FIND_PERSONA";
    public URL_DEPENDENCIA_PERSONA_PERSONA = "URL_DEPENDENCIA_PERSONA_PERSONA";

    https = [
        {name: this.URL_DEPENDENCIA_ID, url: "dependencia/%id%", httpOperation: "get"},
        {name: this.URL_DEPENDENCIA_CODIGO, url: "dependencia/codigo/%codigo%", httpOperation: "get"},
        {name: this.URL_DEPENDENCIA_NIVEL, url: "dependencia/nivel/%nivel%", httpOperation: "get"},
        {name: this.URL_DEPENDENCIA_PARENT, url: "dependencia/parent/%id%", httpOperation: "get"},
        {name: this.URL_PERSONA_DEPENDENCIA_ID, url: "persona/dependencia/%id%", httpOperation: "get"},
        {name: this.URL_NOVEDADES_NUEVO, url: "novedades", httpOperation: "post"},
        {name: this.URL_FIND_DEPENDENCIA, url: "dependencia/find", httpOperation: "post"},
        {name: this.URL_FIND_AVISO, url: "aviso/find", httpOperation: "post"},
        {name: this.URL_TUTORIAL_ALL, url: "tutorial/all/%page%&%size%", httpOperation: "get"},
        {name: this.URL_WEB_SERVICIO_ALL, url: "web/servicio/all/%idDependencia%/%page%&%size%", httpOperation: "get"},
        {name: this.URL_WEB_PRINCIPAL, url: "web/principal/%idDependencia%/%page%&%size%", httpOperation: "get"},
        {name: this.URL_WEB_LINKS, url: "web/links/%idDependencia%/%page%&%size%", httpOperation: "get"},
        {name: this.URL_WEB_LINKS_ORGANICA, url: "web/link-organica/all", httpOperation: "get"},
        {name: this.URL_FIND_PERSONA, url: "persona/find", httpOperation: "post"},
        {name: this.URL_DEPENDENCIA_PERSONA_PERSONA, url: "dependencia/persona/%id%", httpOperation: "get"},
    ]

    constructor(private http: HttpClient, private appConfig: AppConfig) {
    }

    findWs(wsName: string ){
        wsName = wsName.toUpperCase();
        for(let element of this.https){
            if(wsName == element.name.toUpperCase() ){
                return element;
            }
        }
        return null;
    }

    httpFunction(httpName:string, component: any, body?: any, parameters?: any){
        let element = this.findWs(httpName);
        if(!element){
            console.error("No se encontro el wsName=", httpName);
            return;
        }
        let url = element.url;
        //replace parameters
        if(parameters){
            for(let parameter of parameters){
                url = url.replace( "%" + parameter.key + "%", parameter.value);
            }
        }
        if(this.useCache == 1){
            let cache = this.getCache(url,body)
            if(cache){
                this.responseOk(component,cache.urlResource, cache.httpOperation,cache.data,true,cache.ws,cache.body);
                return; //Cache found
            }
        }
        this.httpFunctionCustom(component, element.httpOperation, url, body, element );

    }
    public getCache(urlResource: string, body: any): CacheModel{
        console.error("caches", this.caches);
        for(let cache of this.caches){
            if(cache.urlResource == urlResource && cache.body == body){
                return cache;
            }
        }
        return undefined;
    }
    public setCache(urlResource: string,httpOperation: string, data:any, ws?: any, body?: any){
        let cache = new CacheModel();
        cache.urlResource = urlResource;
        cache.ws = ws;
        cache.body = body;
        cache.data = data;
        this.caches.push(cache);
    }


    public httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
        })
    };


    httpFunctionCustom(component: any, httpOperation: string, urlResource: string, body: any, ws?:any){
        //let urlApi =  environment.server;
        let urlApi = this.appConfig.getConfig("server", "");
        let url = urlApi + urlResource;

        console.warn("httpFunction: " + httpOperation + " " + urlResource + " ==> " + url);
        console.warn("body:", body);
        //environment.server
        switch(httpOperation.toLowerCase()){
            case "get":
                this.httpGet(url).subscribe(data => {this.responseOk(component,urlResource, httpOperation, data,false, ws, body);}, error=>{this.responseError(component,urlResource,httpOperation, error, ws);});
            break;
            case "post":
                this.httpPost(url, body).subscribe(data => {this.responseOk(component,urlResource,httpOperation, data,false, ws, body);}, error=>{this.responseError(component,urlResource,httpOperation, error, ws);});
            break;
            case "put":
                this.httpPut(url, body).subscribe(data => {this.responseOk(component,urlResource,httpOperation, data,false, ws, body);}, error=>{this.responseError(component,urlResource,httpOperation, error, ws);});
            break;
            case "delete":
                this.httpDelete(url).subscribe(data => {this.responseOk(component,urlResource, httpOperation, data,false, ws, body);}, error=>{this.responseError(component,urlResource,httpOperation, error, ws);});
            break;
        }
    }


    private httpGet (url:string): Observable<{}> {
        return this.http
               .get(url, this.httpOptions)
               .pipe(
                    retry(3),
                    // catchError(this.handleError)
               )
    }
    private httpPost (url:string, body:any): Observable<{}> {
        return this.http
               .post(url, body, this.httpOptions)
               .pipe(
                    retry(3),
                    // catchError(this.handleError)
               )
    }
    private httpPut (url:string, body:any): Observable<{}> {
        return this.http
               .put(url, body, this.httpOptions)
               .pipe(
                    retry(3),
                    // catchError(this.handleError)
               )
    }
    private httpDelete (url:string): Observable<{}> {
        return this.http
               .delete(url, this.httpOptions)
               .pipe(
                    retry(3),
                    // catchError(this.handleError)
               )
    }


    public responseOk(component:any, urlResource: string,httpOperation: string, data:any, isCache: boolean, ws?: any, body?: any){
        console.warn("responseOk: " + urlResource, data);
        if(isCache ==false){
            this.setCache(urlResource,httpOperation, data, ws, body);
        }
        component.responseOk(urlResource,httpOperation, data, ws);
    }

    public responseError(component:any, urlResource: string,httpOperation: string, data:any, ws: any){
        // let dataError = '';
        // let dataMessage = '';
        // let dataException = '';

        // if(data){
        //     if(data.message){
        //         dataMessage = data.message;
        //     }
        //     if(data.error){
        //         dataError = data.error;
        //     }
        //     if(data.exception){
        //         dataException = data.exception;
        //     }
        // }
                                //httpOperation:string, http: string, data:any, ws:any
        component.responseError(urlResource, httpOperation, data, ws);
    }
    private handleError(error: HttpErrorResponse) {

        if (error.error instanceof ErrorEvent) {

            console.error('An error occurred:', error.error.message);
        } else {

        console.error(
            `BackEnd Código ${error.status}, ` +
            `body: ${error.error}`);
        }

        return "error";
    }
}
