import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { MainService } from '../../../services/main.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  @Input() menuNombre: string = '';
  @Output() doClick:EventEmitter<any> = new EventEmitter;
  public url: string = '';
  public _menu: number = 0 ;
  ngOnInit(){
    this.url = this.location.path(true);
  }
  constructor(private mainService: MainService, public location: Location) { }

  volver(){
    this.mainService.linkMenu("/home","");
    alert("hola");
  }
  inicio(){

    // Fix: En noticias cuando entras a partir de un micrositio, al pulsar inicio deberia volver al micrositio

    try {
      if(this.url.includes('gestion-judicial')){
        this.location.back();
        return;
      }
      if(this.url.includes('avisos')){
        const urlParts = this.url.split('/');
        const avisosIndex = urlParts.indexOf('avisos');
        if (avisosIndex !== -1 && avisosIndex < urlParts.length - 1) {
            const parametros = urlParts.slice(avisosIndex + 1);
            const parametrosString = parametros.join('/');
            // Tiene parametros, por lo cual hay que volver al inicio
            //alert("parametrosString: " + parametrosString);
            this.location.back(); // Vuelve para atrás
            return;
        }
      }
    }catch(ex){

    }

    this.mainService.linkMenu("/home","");



  }



}
