import { Component, OnInit,Input,Output,EventEmitter, Injectable, OnChanges, SimpleChanges, ViewChild  } from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { Filtro } from 'src/app/models/Filtro';

@Component({
  selector: 'numero-filter',
  templateUrl: './numero-filter.component.html',
  styleUrls: ['./numero-filter.component.css']
})


export class NumeroFilterComponent implements OnChanges{

  @Output() aplicarFiltroEvent = new EventEmitter<Filtro>();
  @Input() filtros : Filtro [] = [];
  numero : string = '';
  anio : string = '';
  disableNumero : boolean;
  disableAnio : boolean;
  numeroInvalido : boolean;
  anioInvalido : boolean;

  constructor() {

  }

  ngOnChanges(changes : SimpleChanges) {
    this.numero = '';
    this.anio = '';
    let filtros : Filtro []  = changes.filtros.currentValue.filter(fil => {
      return fil.tipo === 'numero';
    });
    this.disableNumero  = false;
    this.disableAnio  = false;
    filtros.forEach(fil =>{
      if(fil.operador === '~'){
        let valores = fil.id.split('~');
        this.numero = valores[0];
        this.anio = valores[1];
        this.disableNumero = true;
        this.disableAnio = true;
      }
      if(fil.operador === '>'){
        this.numero = fil.id;
        this.disableNumero = true;
      }
      if(fil.operador === '<'){
        this.anio = fil.id;
        this.disableAnio = true;
      }
    });
  }

  submit(){

    let matchNumero = this.numero.match(/^([0-9]{1,11})$/);
    let matchAnio = this.anio.match(/^([0-9]{4})$/);
    this.numeroInvalido=false;
    this.anioInvalido=false;

    if(this.numero && !matchNumero ){
      this.numeroInvalido=true;
      return;
    }

    if(this.anio && !matchAnio ){
      this.anioInvalido=true;
      return;
    }

    let titulo : string;
    let valor : string;
    let tipo = 'numero';
    let operador : string;

    if(this.numero && this.anio && !(this.disableNumero || this.disableAnio)){
      titulo = `N° ${this.numero}/${this.anio}`;
      operador = '~';
      valor = `${this.numero}~${this.anio}`;
      this.aplicarFiltroEvent.emit(new Filtro(valor, titulo, tipo, 0, operador));
    } else if(this.numero && !this.disableNumero){
      operador = '>';
      titulo = `N° ${this.numero}`;
      valor = this.numero;
      this.aplicarFiltroEvent.emit(new Filtro(valor, titulo, tipo, 0, operador));
    } else if(this.anio && !this.disableAnio) {
      titulo = `Año ${this.anio}`;
      operador = '<';
      valor = this.anio;
      this.aplicarFiltroEvent.emit(new Filtro(valor, titulo, tipo, 0, operador));
    }
  }

}
