import { Component, OnInit,Input  } from '@angular/core';

@Component({
  selector: 'texto',
  templateUrl: './texto.component.html',
  styleUrls: ['./texto.component.css']
})
export class textoComponent implements OnInit {
  @Input() texto: string;
  
  constructor() { }

  ngOnInit() {
  }

}
