import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';



const appRoutes: Routes = [
  { path: '', loadChildren: './Components/home/home.module#HomeModule' },
  { path: 'guia', loadChildren: './Components/guia-organica/guia-organica.module#GuiaOrganicaModule' },
  { path: 'avisos', loadChildren: './Components/avisos/avisos.module#AvisosModule' },
  { path: 'gestion-judicial', loadChildren: './Components/gestion-judicial/gestion-judicial.module#GestionJudicialModule' },
  { path: 'gestion-documental', loadChildren: './Components/gestion-documental/gestion-documental.module#GestionDocumentalModule' },
  { path: 'pagina-anterior', loadChildren: './Components/pagina-anterior/pagina-anterior.module#PaginaAnteriorModule' },
  { path: 'tutoriales', loadChildren: './Components/tutorial/tutorial.module#TutorialModule' },
  { path: 'avisos', loadChildren: './Components/avisos/avisos.module#AvisosModule' },
  { path: 'pages', loadChildren: './Components/page-static/pages.module#PagesModule' },
  { path: 'sitio', loadChildren: './Components/micrositio/micrositio.module#MicroSitioModule' },
  // { path: 'old-browser', loadChildren: './Components/page-static/pages.module#PagesModule' },
  // { path: 'error', loadChildren: './Components/page-static/pages.module#PagesModule' },
  { path: '**',
       redirectTo: '',
       pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {enableTracing: false, anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
