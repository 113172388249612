
export class CacheModel {
    constructor(
            )
    { }
    urlResource: string;
    httpOperation: string;
    data:any; 
    ws: any;
    body: any;
}