
export class DocumentoModel {

    constructor(){ }

    public id : number;
    public publicacion : string;
    public numero : number;
    public anio : number;
    public tipoDesc : string;
    public tipoAdjunto : string;
    public descripcion : string;
    public dependencia : string;
    public rubro : string;
    public fileUrl : string;
    public palabrasClaves : string [];
    public fecha : string;
}
