import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css'],
})

export class LoadingComponent implements OnInit {
  public showSpinner: boolean = true;
  constructor() {
  }

  show() {
    setTimeout(function () {
      this.showSpinner = true;
    }, 10);
  }

  ngOnInit() {
    if(this.showSpinner == false){
      this.show();
    }
  }
  getDisplay(): string{
    if(this.showSpinner == false){
      return "display: none"
    }else{
      return "";
    }
  }
}
