export default class Page <T> {

  constructor(){}

  content : T [];
  totalElements : number;
  totalPages : number;
  last : boolean;
  first : boolean;
  size : number;
  number : number;
  numberOfElements : number;
  empty : boolean;
}
