import { Dependencia } from './Dependencia';

export class MenuModel {
    constructor(
            )
    { }
    public id: number;
    public titulo: string;
    public descripcion: string;
    public imgIcon: string;
    public cuerpo: string; 
    public imgUrl: string;

    public urlInterno: string;
    public urlExterno: string;
    public orden: number; 
    public dependencia: Dependencia; 
}