import { Component, OnInit,Input  } from '@angular/core';

@Component({
  selector: 'titulo-component',
  templateUrl: './titulo.component.html',
  styleUrls: ['./titulo.component.css']
})

export class tituloComponent implements OnInit {
  @Input() titulo: string;
  @Input() titulo2: string;
  @Input() modo: number = 0;
  
  constructor() { }

  ngOnInit() {
  }

}
