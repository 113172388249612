import { MainModule } from '../main/main.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';



import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DataService } from '../../services/data.service';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { TutorialComponent } from './tutorial.component';
import { TutorialInfoComponent } from './tutorial-info/tutorial-info.component';


const appRoutes: Routes = [
  { path: '',  component: TutorialComponent },
];


@NgModule({
  declarations: [
    TutorialInfoComponent,
    TutorialComponent
    
  ],
  imports: [
    CommonModule,
    NgbModule.forRoot(),
    RouterModule.forChild(appRoutes),
    MainModule,
    FormsModule,
    HttpClientModule,

  ],
  providers: [DataService],
  bootstrap: []
})
export class TutorialModule { }
