import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { Inject} from "@angular/core";
import { MenuModel } from '../../../models/MenuModel';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { DataService } from '../../../services/data.service';
import { LinkMenuModel } from '../../../models/LinkMenuModel';
import { MainService } from '../../../services/main.service';

@Component({
  selector: 'home-links-menu',
  templateUrl: './links-menu.component.html',
  styleUrls: ['./links-menu.component.css'],
})

export class LinksMenuComponent implements OnInit {
  @Input() idDependencia: number = -1;
  links:   LinkMenuModel[] = [];
  pageNumber: number = 0;
  pageSize: number = 100;
  loaded: boolean = false;
  data;


  constructor(public dataService: DataService, public route: ActivatedRoute,public _sanitizer: DomSanitizer, public router: Router, public mainService: MainService){

  }

  clickMenu(link: LinkMenuModel){
    this.mainService.linkMenu(link.urlInterno, link.urlExterno);
  }

  getWidth(link: LinkMenuModel): string{
    if(link.width && link.width.length > 0){
      return link.width;
    }else{
      return "100%";
    }
  }
  getHeight(link: LinkMenuModel){
    if(link.height && link.height.length > 0){
      return link.height;
    }else{
      return "auto";
    }
  }

  getBackgroundUrl(url: string){
    return this._sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }

  ngOnInit() {
  	this.loadData();
  }

  loadData(){
    this.loaded = false;
    if(this.pageNumber <= 0 ){
      this.pageNumber = 1;
    }
    let parameters = [{"key":"page", "value": this.pageNumber-1}, {"key": "size", "value": this.pageSize}, {"key": "idDependencia", "value": this.idDependencia}];
    this.dataService.httpFunction(this.dataService.URL_WEB_LINKS,this,"",parameters);
  }

  responseOk(httpOperation:string, http: string, data:any, ws:any ){
    //Procesar-Data
    switch(ws.name){
      case this.dataService.URL_WEB_LINKS:
        this.data = data;
        this.links = data.content;
        this.loaded = true;
      break;
    }
  }
  responseError(httpOperation:string, http: string, data:any, ws:any ){
    // alert("Error");
    this.loaded = true;
  }



}
