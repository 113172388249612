import { Observable } from "rxjs/Observable";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { Component, OnInit, Input } from "@angular/core";
import { Inject } from "@angular/core";
import { MenuModel } from "../../../models/MenuModel";
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from "@angular/platform-browser";
import { DataService } from "../../../services/data.service";

@Component({
  selector: "home-servicios",
  templateUrl: "./servicios.component.html",
  styleUrls: ["./servicios.component.css"],
})
export class ServiciosComponent implements OnInit {
  @Input() idDependencia: number = -1;
  @Input() titulo: string = "Trámites y Servicios";

  menus: MenuModel[] = [];
  pageNumber: number = 0;
  pageSize: number = 100;
  loaded: boolean = false;
  data;

  constructor(
    public dataService: DataService,
    private route: ActivatedRoute,
    public _sanitizer: DomSanitizer,
    public router: Router
  ) {}

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loaded = false;
    if (this.pageNumber <= 0) {
      this.pageNumber = 1;
    }
    let parameters = [
      { key: "page", value: this.pageNumber - 1 },
      { key: "size", value: this.pageSize },
      { key: "idDependencia", value: this.idDependencia },
    ];
    this.dataService.httpFunction(
      this.dataService.URL_WEB_SERVICIO_ALL,
      this,
      "",
      parameters
    );
  }

  showPagination() {
    if (this.data) {
      if (this.data.totalPages > 1) {
        return true;
      }
    }
    return false;
  }

  responseOk(httpOperation: string, http: string, data: any, ws: any) {
    //Procesar-Data
    switch (ws.name) {
      case this.dataService.URL_WEB_SERVICIO_ALL:
        this.data = data;
        this.menus = data.content;
        this.loaded = true;
        break;
    }
  }

  chunkArray(arr: any[], chunkSize: number): any[] {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  }

  responseError(httpOperation: string, http: string, data: any, ws: any) {
    this.loaded = true;
    // alert("Error");
  }

  getRows(menus: any[]): any[][] {
    const rows = [];
    for (let i = 0; i < menus.length; i += 3) {
      rows.push(menus.slice(i, i + 3));
    }
    return rows;
  }

  getBackgroundUrl(url: string) {
    return this._sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }
}
