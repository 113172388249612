import { Observable } from "rxjs/Observable";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Inject } from "@angular/core";
import { MenuModel } from "../../../models/MenuModel";
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from "@angular/platform-browser";
import { DataService } from "../../../services/data.service";
import { MainService } from "../../../services/main.service";
// import { log } from "console";

@Component({
  selector: "home-menus",
  templateUrl: "./menus.component.html",
  styleUrls: ["./menus.component.css"],
})
export class MenusComponent implements OnInit {
  @Input() idDependencia: number = -1;
  @Output() error: EventEmitter<any> = new EventEmitter();

  menus: MenuModel[] = [];
  pageNumber: number = 0;
  pageSize: number = 100;
  loaded: boolean = false;
  data;

  constructor(
    public dataService: DataService,
    public mainService: MainService,
    public route: ActivatedRoute,
    public _sanitizer: DomSanitizer,
    public router: Router
  ) {}
  getClase() {
    if (this.idDependencia != -1) {
      return "col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4";
    } else {
      return "col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4";
    }
  }

  getBackgroundUrl(url: string) {
    return this._sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loaded = false;
    if (this.pageNumber <= 0) {
      this.pageNumber = 1;
    }
    let parameters = [
      { key: "page", value: this.pageNumber - 1 },
      { key: "size", value: this.pageSize },
      { key: "idDependencia", value: this.idDependencia },
    ];
    this.dataService.httpFunction(
      this.dataService.URL_WEB_PRINCIPAL,
      this,
      "",
      parameters
    );
  }

  responseOk(httpOperation: string, http: string, data: any, ws: any) {
    //Procesar-Data
    switch (ws.name) {
      case this.dataService.URL_WEB_PRINCIPAL:
        this.data = data;
        this.menus = data.content;
        this.loaded = true;
        break;
    }
  }
  responseError(httpOperation: string, http: string, data: any, ws: any) {
    this.error.emit();
  }
}
