import { Component, OnInit,Input,Output,EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DocumentoModel } from '../../../models/documentoModel';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import Page from 'src/app/models/Page';


@Component({
  selector: 'listado-busqueda',
  templateUrl: './listado-busqueda.component.html',
  styleUrls: ['./listado-busqueda.component.css']
})

/**
 * El componente permite mostrar el listado de resultados
*/
export class ListadoBusquedaComponent implements OnChanges, OnInit{

  /******************************************************************************************************
   * Inputs && Outputs
  ******************************************************************************************************/
  @Input() page: Page<DocumentoModel>;
  @Output() newPageEvent = new EventEmitter<number>();
  documentos : DocumentoModel [] = [];
  breakpoints = Breakpoints;

  public sm: boolean = false;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

    constructor(private breakpointObserver: BreakpointObserver) { }

    ngOnInit(): void {
      this.breakpoint$.subscribe(() =>
        this.breakpointChanged()
      );
    }

    private breakpointChanged() {
      if(this.breakpointObserver.isMatched(Breakpoints.Small) || this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
        this.sm = true;
      } else {
        this.sm = false;
      }
    }

    getSize():string{
      return this.sm ? 'sm' : '-';
    }


  ngOnChanges(changes : SimpleChanges) {
    this.documentos = changes.page.currentValue ? changes.page.currentValue.content : [];
  }

   getClassRow(index: number){
    if(index%2==0){
      return "even";
    }else{
      return "odd";
    }
  }

  pageChange(newPage : number){
    this.newPageEvent.emit(newPage);
  }

}
