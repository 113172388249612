import { Component, Input,  Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Filtro } from 'src/app/models/Filtro';

@Component({
  selector: 'app-search-resume',
  templateUrl: './search-resume.component.html',
  styleUrls: ['./search-resume.component.css']
})
export class SearchResumeComponent implements OnChanges {

  @Input() filtros : Filtro [] = [];
  @Input() resultados : number;
  @Input() termino : string;
  @Input() small : boolean = false;
  @Output() removeFiltroEvent = new EventEmitter<Filtro>();
  @Output() resetFiltro = new EventEmitter<boolean>();
  showReset : boolean = false;
  shared : boolean = false;


  constructor(private toastr: ToastrService, private route: ActivatedRoute) { }

  ngOnChanges(changes : SimpleChanges) {
    this.showReset = this.filtros.length > 0 || !(this.termino === '' || this.termino == null);
  }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParam => {
      this.shared = queryParam.sd;
    });
  }
  
  reset(){
    this.resetFiltro.emit(true);
  }

  remover(filtro : Filtro){
    this.removeFiltroEvent.emit(filtro);
  }

  share(){
    let url = window.location.href.endsWith("sd=true") ? window.location.href : window.location.href+"&sd=true";
    navigator.clipboard.writeText(url).then(
      result => {
        this.toastr.success("Url de busqueda copiada en papeleta.")
      }
    ).catch(e => console.error(e));
  }

}
