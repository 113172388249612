import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { DependenciaMock } from './dependencia.mock';
import { DataService } from './../services/data.service';

@Injectable()
export class MockService{


    constructor(public dependenciaMock: DependenciaMock) { 
    }
    

    httpFunctionCustom(component: any, httpOperation: string, urlResource: string, body: any, ws?:any, parameters?: any){
        console.error("MOCKSERVICE", httpOperation,urlResource,body,ws,parameters);
        
        let data;
        switch(urlResource.toLowerCase()){
            case "dependencia/parent/%id%":
                data = this.dependenciaMock.getData(httpOperation,urlResource,body,ws,parameters);
            break;
        }
        console.warn("MOCKSERVICE-DATA", data);
        this.responseOk(component,urlResource,httpOperation,data,ws);        
    }
    public responseOk(component:any, urlResource: string,httpOperation: string, data:any, ws?: any){
        console.warn("responseOk-MOCK: " + urlResource, data);
        component.responseOk(urlResource,httpOperation, data, ws);
    }    
}