import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { Inject} from "@angular/core";
import { AvisoModel } from '../../../models/AvisoModel';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Dependencia } from '../../../models/Dependencia';
import { MainService } from '../../../services/main.service';
import { Link } from '../../../models/Link';

@Component({
  selector: 'aviso-info',
  templateUrl: './aviso-info.component.html',
  styleUrls: ['./aviso-info.component.css'],
})

export class AvisoInfoComponent implements OnInit {

  @Input() aviso: AvisoModel;
  @Input() showResumen: number = 0;
  @Input() interes: number = 0;
  @Input() index: number = 0;

  public links: Link[] = [];

  constructor(public route: ActivatedRoute,public _sanitizer: DomSanitizer, public router: Router, public mainService: MainService){
  }
  
  getDependenciaInfoNombre(aviso: AvisoModel){
    if(aviso.dependencia){
      if(aviso.dependencia.dependenciaInfo){
        return aviso.dependencia.dependenciaInfo.nombre;
      }
    }
    return ""; 
  }
  public link(link: Link){
    this.mainService.linkMenu(link.urlInterno, link.urlExterno);
  }

  getData(obj: AvisoModel){
    try{
      let resumen: string = "";
      if(obj.resumen){
        resumen = obj.resumen;
      }else{
        resumen = "";
      }
      let cuerpo: string = "";
      if(obj.cuerpo){
        cuerpo = obj.cuerpo;
      }else{
        cuerpo = "";
      }

      
      if((resumen!= "") && this.showResumen == 1){
        return resumen;
      }else{
        return cuerpo;
      }      
    }catch(ex){
      return "";
    }

  }
  public getClassName(){
    let rest = this.index % 3;
    switch(rest){
      case 0:
        return "card-azul";
      case 1: 
        return "card-amarillo";
      case 2:
        return "card-rojo";
    }
  }



  getBackgroundUrl(url: string){
    return this._sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }  
  
  addLink(urlTexto: any, urlInterno: any, urlExterno: any){
    let link: Link;
    link = new Link();

    
    urlTexto = this.mainService.getString(urlTexto);
    if(urlTexto == "" || urlTexto.length < 2){
      urlTexto = "Link";
    }
    
    urlInterno = this.mainService.getString(urlInterno);
    urlExterno = this.mainService.getString(urlExterno);    
    if(urlInterno == "" && urlExterno == ""){
      return;
    }


    link.texto =urlTexto;
    link.urlInterno = urlInterno;
    link.urlExterno = urlExterno;
    this.links.push(link);
  }

  procesarData(){
    this.links = [];
    this.addLink(this.aviso.urlTexto, this.aviso.urlInterno, this.aviso.urlExterno);
    this.addLink(this.aviso.urlTexto2, this.aviso.urlInterno2, this.aviso.urlExterno2);
    this.addLink(this.aviso.urlTexto3, this.aviso.urlInterno3, this.aviso.urlExterno3);
  }
  ngOnInit(){
    this.procesarData();
  }

}
