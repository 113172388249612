import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AvisoModel } from "src/app/models/AvisoModel";
import { LinkOrganica } from "src/app/models/LinkOrganica";
import { LinkOrganicaResponse } from "src/app/models/LinkOrganicaResponse";
import { DataService } from "src/app/services/data.service";
import { MainService } from "src/app/services/main.service";

@Component({
  selector: "app-home-interes",
  templateUrl: "./home-interes.component.html",
  styleUrls: ["./home-interes.component.css"],
})
export class HomeInteresComponent implements OnInit {
  @Output() doClick: EventEmitter<any> = new EventEmitter();

  public interes: AvisoModel[];
  public data;
  public pageSize: number = 10;
  public pageNumber: number = 1;
  public loaded: boolean = false;

  constructor(
    public dataService: DataService,
    private route: ActivatedRoute,
    public router: Router,
    public mainService: MainService
  ) {}

  
  clickLink(link: AvisoModel) {
    this.mainService.linkMenu(link.urlInterno, link.urlExterno);

    // if(link.urlInterno && link.urlInterno.length>0){
    //   this.router.navigate([link.urlInterno]);
    // }else{
    //   if(link.urlExterno && link.urlExterno.length > 0 ){
    //     window.location.href = link.urlExterno;
    //   }
    // }
  }
  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loaded = false;
    let body = {
      "idDependencia": -1,
      "interes": 1,
      "page": 0,
      "size": 1000
    };
    this.dataService.httpFunction(
      this.dataService.URL_FIND_AVISO,
      this,
      body,
      ""
    );
  }

  procesarData(avisos: AvisoModel[]): AvisoModel[] {
    // avisos = [];
    // var aviso1 = new AvisoModel();
    // aviso1.titulo = "prueba";
    // aviso1.cuerpo = "información";

    // avisos.push(aviso1);
    
    return avisos;
  }

  responseOk(httpOperation: string, http: string, data: any, ws: any) {
    //Procesar-Data
    switch (ws.name) {
      case this.dataService.URL_FIND_AVISO:
        this.data = data;
        this.interes = this.procesarData(data.content);
        this.loaded = true;
        break;
    }
  }
  responseError(httpOperation: string, http: string, data: any, ws: any) {
    // alert("E");
    this.loaded = true;
  }
}
