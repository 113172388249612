import { DataService } from './services/data.service';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';

import {HttpClientModule} from '@angular/common/http';


import { MainModule } from './Components/main/main.module';
import { RouterModule } from '@angular/router';
import { HomeModule } from './Components/home/home.module';
import { GestionDocumentalModule } from './Components/gestion-documental/gestion-documental.module';
import { AppRoutingModule } from './app-routing.module';
import { MockService } from './mock/mock.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AvisosModule } from './Components/avisos/avisos.module';
import { TutorialModule } from './Components/tutorial/tutorial.module';
import { PaginaAnteriorModule } from './Components/pagina-anterior/pagina-anterior.module';
import { MainService } from './services/main.service';

import { APP_INITIALIZER } from '@angular/core';
import {AppConfig} from './app.config';

import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


export function initConfig(config: AppConfig) {
  return () => config.load();
}

registerLocaleData(localeEsAr, 'es-Ar');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    RouterModule.forRoot([]),
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MainModule,
    HomeModule,
    GestionDocumentalModule,
    TutorialModule,
    PaginaAnteriorModule,
    AvisosModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [
  ],
  providers: [DataService, MockService, MainService, { provide: LOCALE_ID, useValue: 'es-AR' } ,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfig],
      multi: true
    }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
