import { Component, OnInit, Input } from '@angular/core';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.css']
})
export class TitleComponent implements OnInit {
  @Input() useBackground: boolean=false;
  @Input("tipo")
  set tipo(value: string) {
    this._tipo = value;
    this.update();
  }
  _tipo: string = "";
  permiteBusqueda: boolean =false;
  mostrarSeparador: boolean = true;
  descripcion: string = "";
  titulo: string = "";
  backgroundUrl: string = "";
  backgroundClass: string = "";

  showBreadcrumb: boolean =true;
  public showTitle: boolean = true;
  pages = [];


  constructor(private appConfig: AppConfig) {
    this.showTitle = true;
  }

  loadConfig(){
    try{
      this.pages = this.appConfig.getConfig("pages",[]);
    }catch(ex){
      this.pages = [];
    }
  }
  getConfig(nameUrl: string){
    if (nameUrl.indexOf("?",0) >= 0){
      nameUrl = nameUrl.substring(0, nameUrl.indexOf("?",0));
    }

    try{
      if(this.pages){
        for(let p of this.pages){
          let url: String = p.url;
          if(url && url.toLowerCase() == nameUrl){
            return p;
          }
        }
        return undefined;
      }
    }catch(ex){
      return undefined;
    }
  }
  getValue(page: any, key: string, defaultValue: any): any{
    try{
      return page[key];
    }catch(ex){
      return defaultValue;
    }
  }

  update(){
    this.showTitle = true;
    let cads: string[] = this._tipo.split("/");
    let strPath = "";
    if(cads.length > 1){
      strPath = cads[1];
      if(strPath.toLowerCase().trim() == "pages"){
        strPath = cads[2];
      }
    }else{
      strPath = this._tipo;
    }


    this.showBreadcrumb = true;
    this.mostrarSeparador = true;
    this.backgroundClass = "background-home";
    this.permiteBusqueda=false;

    let page = this.getConfig(strPath.toLowerCase().trim());
    if(page != undefined){
      let titulo: string = this.getValue(page, "title", "");
      if(titulo.trim() != ""){
        this.titulo = titulo;
        this.descripcion = this.getValue(page, "sub", "");
        this.showBreadcrumb = this.getValue(page, "showBreadcrumb", true);
        return;
      }
    }


    switch(strPath.toLowerCase().trim()){
      case "guia":
        this.titulo = "Guía Judicial";
        this.descripcion = "Directorio de órganos judiciales, dependencias y funcionarios del Poder Judicial de la Nación.";
        this.mostrarSeparador = false;
        this.showBreadcrumb = false;
      break;
      case "gestion-judicial":
        this.titulo = "Gestión Judicial";
        this.permiteBusqueda=false;
        this.backgroundClass = "background-home";
        this.descripcion = "Acceda a los sistemas de gestión e ingresos de causas y conozca sus novedades y tutoriales.";
      break;
      case "gestion-documental":
        this.titulo = "Gestión Documental";
        this.permiteBusqueda=false;
        this.backgroundClass = "background-home";
        this.descripcion = "'Acceda al modulo de consulta documental.";
        this.showTitle =false;
        this.showBreadcrumb = false;
      break;
      case "avisos":
        this.titulo = "Avisos";
        this.backgroundClass = "background-home";
        this.descripcion = "Muestra los avisos del Poder Judicial.";
      break;
      case "novedades":
        this.titulo = "Novedades";
        this.permiteBusqueda=false;
        this.backgroundClass = "background-home";
        this.descripcion = "Noticias relevantes del Consejo de la Magistratura";
      break;
      case "tutoriales":
        this.titulo = "Tutoriales";
        this.backgroundClass = "background-home";
        this.descripcion = "Permite acceder a manuales y tutoriales.";
      break;
      case "instructivo-auxiliares":
        this.titulo = "Inscripción y Sistema de Gestión Auxiliares de Justicia";
        this.backgroundClass = "background-home";
        this.descripcion = "Requisitos e información sobre el proceso de inscripción de auxiliares de justicia.";
      break;
      case "inscripcion-concursos":
        this.titulo = "Inscripción a concursos de la Justicia Nacional y Federal";
        this.backgroundClass = "background-home";
        this.descripcion = "Pasos a seguir para la inscripción a Concursos Públicos de Oposición y Antecedentes para la designación de magistrados del Poder Judicial de la Nación.";
      break;
      case "contacto":
        this.titulo = "Contactos";
        this.backgroundClass = "background-home";
        this.descripcion = "";
      break;
      case "sitio":
          this.showTitle = false;
      default: //Home
        this.descripcion = "Acceda a información institucional, realice consultas y trámites judiciales."
        this.backgroundClass = "background-home";
        this.titulo = "Poder Judicial de la Nación";
        this.showBreadcrumb = false;
      break;
    }
  }
  ngOnInit() {
   this.loadConfig();
   this.update();
  }

  getBackgroundClass(){
      return this.backgroundClass;
  }

}
