import { Dependencia } from './Dependencia';
export class AvisoModel {
    public titulo: string;
    public cuerpo: string;
    public fechaAlta: string;
    public fechaBaja: string;
    public resumen: string;
    
    public urlTexto: string
    public urlExterno: string;
    public urlInterno: string; 

    public urlTexto2: string
    public urlExterno2: string;
    public urlInterno2: string; 

    public urlTexto3: string
    public urlExterno3: string;
    public urlInterno3: string; 

    public dependencia: Dependencia;

    constructor() {
   
    }


}

