import { tituloComponent } from './titulo/titulo.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {HttpClientModule} from '@angular/common/http';
import { textoComponent } from './texto/texto.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { TitleComponent } from './title/title.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavBarComponent } from './navbar/navbar.component';
import { AvisosComponent } from './avisos/avisos.component';
import { MenusComponent } from './menus/menus.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { AvisoInfoComponent } from '../avisos/aviso-info/aviso-info.component';
import { MenuInfoComponent } from './menu-info/menu-info.component';
import { LinksMenuComponent } from './links/links-menu.component';
import { LoadingComponent } from './loading/loading.component';
import { FooterPostComponent } from './footer-post/footer-post.component';
import { SitiosInteresComponent } from '../home/footer/sitios-interes.component';



@NgModule({
  declarations: [
    MenuInfoComponent,
    NavBarComponent,
    tituloComponent,
    textoComponent,
    AvisoInfoComponent,
    AvisosComponent,
    MenusComponent,
    BreadcrumbComponent,
    TitleComponent,
    ServiciosComponent,
    LinksMenuComponent,
    LoadingComponent,
    FooterPostComponent,
    SitiosInteresComponent
  ],
  imports: [
    NgbModule.forRoot(),
    RouterModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
  ],
  exports: [
    MenuInfoComponent,
    NavBarComponent,
    SitiosInteresComponent,
    tituloComponent,
    textoComponent,
    AvisosComponent,
    AvisoInfoComponent,
    MenusComponent,
    BreadcrumbComponent,
    TitleComponent,
    ServiciosComponent,
    LinksMenuComponent,
    LoadingComponent,
    FooterPostComponent
  ],
  providers: [],
  bootstrap: []
})
export class MainModule { }
