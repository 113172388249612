import { Observable } from 'rxjs/Observable';
import { Component, OnInit, Inject, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute, Route, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { DataService } from './services/data.service';



@Component({
  selector: 'app-root',
  providers: [],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, AfterViewChecked {
  sector: string;
  showBreadCrumb: boolean = false;
  // constructor(private router: Router, private location: Location,  private route: Route, private activatedRoute: ActivatedRoute, private dataService: DataService, @Inject(DOCUMENT) private document: Document
  //             ){
  route: string = "";

  constructor(location: Location, router: Router) {
    router.events.subscribe(val => {
      if (location.path() != "") {
        this.route = location.path();
      } else {
        this.route = "Home";
      }
      //window.scrollTo(0, 0);
      // console.error("route--->", this.route);
    });
  }
  // console.error(this.location.protocol);
  ngAfterViewChecked() {
    //window.scrollTo(0, 0);
  }

  ngOnInit() {
    // console.error("activatedRoute", this.activatedRoute);
    // console.error("Location", this.location);
    // console.error("route", this.route);
  }

}
