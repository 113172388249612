import { AbstractMock } from './AbstractMock';

export class DependenciaMock extends AbstractMock{
    data = [
      {"id":7,"dependenciaInfo":{"id":7,"localidad":{"id":1,"nombre":"CABA"},"nombre":"Organización y Sistemas","tipo":0,"direccion":"Libertad 731 Piso 8","telefono":"4442-45824","email":"organizacion@pjn.gov.ar","dependencia":null,"codigoPostal":null,"fax":null},"idParent":5,"nivel":2}
    ];

    constructor(){
      super();
    }

    find(id: number): any{
      for (let index = 0; index < this.data.length; index++) {
        const element = this.data[index];
        if(element.id == id){
          return element;
        }
      }
      return null;
    }


    
    getData(httpOperation: string, urlResource: string, body: any, ws?:any, parameters?: any){
      let id = this.getParameter("id", parameters);
      return this.find(id);
    }
}