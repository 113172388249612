import { Inject, Injectable } from '@angular/core';
import {HttpClientModule, HttpClient} from '@angular/common/http';

import { Observable } from 'rxjs/Rx';

export class envType{
  public env: string; 
}

@Injectable()
export class AppConfig {


  private config: Object = null;
  private env:    envType = null;

  constructor(private http: HttpClient) {
  }

  /**
   * Use to get the data found in the second file (config file)
   */
  public getConfig(key: any, defaultValue: any) {
    try{
      return this.config[key];  
    }catch(ex){
      return defaultValue; 
    }
    
  }

  /**
   * Use to get the data found in the first file (env file)
   */
  public getEnv(key: any) {
    return this.env[key];
  }

  /**
   * This method:
   *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
   *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
   */
  public load() {
    return new Promise((resolve, reject) => {
      this.http.get('./assets/env/env.json').map( res => res ).catch((error: any): any => {
        console.log('Configuration file "env.json" could not be read');
        resolve(true);
        return Observable.throw(error.json().error || 'Server error');
      }).subscribe( (envResponse) => {
        this.env = Object (envResponse);
        let envstr: envType = this.env;
        
        let request: any = null;

        switch (envstr.env) {
          case 'prod': {
            request = this.http.get('./assets/env/env.prod.json');
          } break;

          case 'dev': {
            request = this.http.get('./assets/env/env.dev.json');
          } break;

          case 'local': {
            request = this.http.get('./assets/env/env.local.json');
          } break;

          default: {
            request = this.http.get('./assets/env/env.config.json');
            resolve(true);
          } break;
        }

        if (request) {
          request
            .map( res => res )
            .catch((error: any) => {
              console.error('Error reading ' + envstr.env + ' configuration file ',  error);
              resolve(error);
              return Observable.throw(error.json().error || 'Server error');
            })
            .subscribe((responseData: any) => {
              this.config = responseData;
              resolve(true);
            });
        } else {
          console.error('Env config file "env.json" is not valid');
          resolve(true);
        }
      });

    });
  }
}