import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { Inject} from "@angular/core";
import { MenuModel } from '../../../models/MenuModel';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { MainService } from '../../../services/main.service';

@Component({
  selector: 'menu-info',
  templateUrl: './menu-info.component.html',
  styleUrls: ['./menu-info.component.css'],
})

export class MenuInfoComponent implements OnInit {
  @Input() menu:   MenuModel;
  @Input() principal: number = 0;

  constructor(public route: ActivatedRoute,public mainService: MainService, public _sanitizer: DomSanitizer, public router: Router){
  }
  
  getBackgroundUrl(url: string){
    return this._sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }  
  openLink(menu: MenuModel){
    // console.error("openLink", menu);
    // if(menu.external == false){
      //this.router.navigate([menu.url]);
    // }else{
      //window.location.href = menu.url;
    // }
  }
  clickMenu(menu: MenuModel){
    this.mainService.linkMenu(menu.urlInterno, menu.urlExterno);
  }

  ngOnInit(){
  }
  }
