import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
// import { Http2ServerRequest } from 'http2';
// import { MockService } from '../mock/mock.service';
import { ActivatedRoute, Router } from '@angular/router';


@Injectable()
export class MainService{


    constructor(private http: HttpClient,  private route: ActivatedRoute, public router: Router) { 
    }
    
    linkMenu(urlInterna: string, urlExterna: string){
        if(urlInterna && urlInterna.length>0){
            this.router.navigate([urlInterna]);
          }else{
            if(urlExterna && urlExterna.length > 0 ){
              window.open(urlExterna,"_blank");
            }
          }
    }
    
    getString(texto: any){
      try{
        if(!texto){
          return "";
        }
        let textX: string = texto;
        if(textX.length > 0){
          return textX;
        }else{
          return "";
        }
      }catch(ex){
        return "";
      }
    }

    getBrowser(){   //[0]-Navegador (Firefox/Chrome/Opera) [1]-Version
      var ua= navigator.userAgent, tem, 
      M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if(/trident/i.test(M[1])){
          tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
          return 'IE '+(tem[1] || '');
      }
      if(M[1]=== 'Chrome'){
          tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
          if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
      if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
      return M;
    }
   
 
}