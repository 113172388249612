import { MainModule } from '../main/main.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';



import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DataService } from '../../services/data.service';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AvisosComponent } from './avisos.component';


const appRoutes: Routes = [
  { path: ':idDependencia',  component: AvisosComponent },
  { path: '',  component: AvisosComponent },
];


@NgModule({
  declarations: [
    AvisosComponent,
  ],
  imports: [
    CommonModule,
    NgbModule.forRoot(),
    RouterModule.forChild(appRoutes),
    MainModule,
    FormsModule,
    HttpClientModule,

  ],
  providers: [DataService],
  bootstrap: []
})
export class AvisosModule { }
