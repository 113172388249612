import { Component, OnInit } from '@angular/core';
import { AvisoModel } from '../../models/AvisoModel';
import { DataService } from '../../services/data.service';
import { ActivatedRoute } from '@angular/router';
import {NgbPaginationConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.css'],
  providers:[NgbPaginationConfig]
})
export class TutorialComponent implements OnInit {

   public tutoriales = [];
   public data; 
   public pageSize: number = 10;
   public pageNumber: number = 1; 
   public loaded: boolean = false; 
   


   constructor(public dataService: DataService, private route: ActivatedRoute, private configPaginator: NgbPaginationConfig){
    configPaginator.size = "lg";
  }

  ngOnInit() {
  	this.loadData();
  }

  loadData(){
    this.loaded = false;
    if(this.pageNumber <= 0 ){
      this.pageNumber = 1;
    }
    let parameters = [{"key":"page", "value": this.pageNumber-1}, {"key": "size", "value": "30"}];
    this.dataService.httpFunction(this.dataService.URL_TUTORIAL_ALL,this,"",parameters);
  }

  

  showPagination(){
    if(this.data){
      if(this.data.totalPages>1){
        return true;
      }
    }
    return false; 
  }
  responseOk(httpOperation:string, http: string, data:any, ws:any ){
    //Procesar-Data
    switch(ws.name){
      case this.dataService.URL_TUTORIAL_ALL:
        this.data = data;
        this.tutoriales = data.content;      
        this.loaded = true;  
      break;
   
      
    }
  }
  responseError(httpOperation:string, http: string, data:any, ws:any ){
    // alert("Error");
    this.loaded = true;
  }
}
