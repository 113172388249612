import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { Inject} from "@angular/core";
import { AvisoModel } from '../../../models/AvisoModel';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Tutorial } from '../../../models/Tutorial';

@Component({
  selector: 'tutorial-info',
  templateUrl: './tutorial-info.component.html',
  styleUrls: ['./tutorial-info.component.css'],
})

export class TutorialInfoComponent implements OnInit {

  @Input() tutorial: Tutorial;
  @Input() index: number = 0;

  constructor(public route: ActivatedRoute,public _sanitizer: DomSanitizer, public router: Router){
  }

  getData(){
      return this.tutorial.cuerpo;
  }
  
  tieneLink(){
    if(this.tutorial.urlExterno && this.tutorial.urlExterno.length > 0){
      return true;
    }else{
      return false; 
    }
  }
  getLink(){
    if(this.tieneLink()){
      return this.tutorial.urlExterno;
    }
  }
  getTextoLink(){
    if(this.tieneLink()){
      if(this.tutorial.urlTexto && this.tutorial.urlExterno.length > 0){
        return this.tutorial.urlTexto;
      }
    }
    return "link";
  }


  public getClassName(){
    let rest = this.index % 3;
    switch(rest){
      case 0:
        return "card-azul";
      case 1: 
        return "card-amarillo";
      case 2:
        return "card-rojo";
    }
  }
  public getIcon(){
    if(this.tutorial.icon && this.tutorial.icon.length > 0){
      return this.tutorial.icon;
    }else{
      return "fa-book";
    }
  }


  getBackgroundUrl(url: string){
    return this._sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }  

  ngOnInit(){
  }

}
