import { MainModule } from './../main/main.module';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { GestionDocumentalComponent } from './gestion-documental.component';
import { ListadoBusquedaComponent } from './listado-busqueda/listado-busqueda.component';
import { InputBusquedaComponent } from './input-busqueda/input-busqueda.component';
import { FiltrosEspecialesComponent } from './filtros-especiales-busqueda/filtros-especiales-busqueda.component';
import { ItemBusquedaComponent } from './listado-busqueda/item-busqueda/item-busqueda.component';
import { BreadcrumbDependenciaComponent } from './breadcrumb-dependencia/breadcrumb-dependencia.component';
import { SearchResumeComponent } from './search-resume/search-resume.component';
import { CategoryFilterComponent } from './category-filter/category-filter.component';
import { NumeroFilterComponent } from './numero-filter/numero-filter.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';


const appRoutes: Routes = [
  { path: '**', component: GestionDocumentalComponent }
];


@NgModule({
  declarations: [
    GestionDocumentalComponent,
    InputBusquedaComponent,
    ListadoBusquedaComponent,
    FiltrosEspecialesComponent,
    ItemBusquedaComponent,
    BreadcrumbDependenciaComponent,
    SearchResumeComponent,
    CategoryFilterComponent,
    NumeroFilterComponent
  ],
  imports: [
    CommonModule,
    NgbModule.forRoot(),
    RouterModule.forChild(appRoutes),
    FormsModule,
    HttpClientModule,
    MainModule,
    NgxSpinnerModule,
    PdfJsViewerModule
  ],
  exports: [

  ],
  providers: [],
  bootstrap: [GestionDocumentalComponent],
})
export class GestionDocumentalModule { }
