import { Component, OnInit } from '@angular/core';
import { AvisoModel } from '../../models/AvisoModel';
import { DataService } from '../../services/data.service';
import { ActivatedRoute } from '@angular/router';
import {NgbPaginationConfig} from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';

@Component({
  selector: 'avisos',
  templateUrl: './avisos.component.html',
  styleUrls: ['./avisos.component.css'],
  providers:[NgbPaginationConfig]
})
export class AvisosComponent implements OnInit {

   public avisos = [];
   public data;
   public pageSize: number = 10;
   public pageNumber: number = 1;
   public loaded: boolean = false;
   public loading: boolean = false;
   public idDependencia: string ="-1";
   public previousUrl;


   constructor(public dataService: DataService, private route: ActivatedRoute, private configPaginator: NgbPaginationConfig, public location: Location){
    configPaginator.size = "lg";
  }


  ngOnInit() {
    this.previousUrl = this.location;
    console.warn("PreviousUrl",this.previousUrl);
    console.warn(this.location);

    this.idDependencia = this.route.snapshot.paramMap.get("idDependencia");
    if(this.idDependencia){
      if(this.idDependencia.toString().toLowerCase() == "todos"){
        this.idDependencia = "-1";
      }
    }else{
      this.idDependencia = "-1";
    }
    this.loadAvisos();
  }

  loadAvisos(){
    this.loaded = false;
    if(this.pageNumber <= 0 ){
      this.pageNumber = 1;
    }
    this.loading = true;
    let body = {"idDependencia": this.idDependencia, "nombre": "", "page": this.pageNumber-1 };
    this.dataService.httpFunction(this.dataService.URL_FIND_AVISO,this,body,"");
  }



  showPagination(){
    if(this.data){
      if(this.data.totalPages>1){
        return true;
      }
    }
    return false;
  }
  responseOk(httpOperation:string, http: string, data:any, ws:any ){
    //Procesar-Data
    this.loading = false;
    switch(ws.name){
      case this.dataService.URL_FIND_AVISO:
        this.data = data;
        this.avisos = data.content;
        this.loaded = true;
      break;


    }
  }
  responseError(httpOperation:string, http: string, data:any, ws:any ){
    this.loading = false;
  }
}
