import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
  enableProdMode();
  if(window){
    window.console.log=function(){};
    window.console.warn=function(){};
    window.console.error=function(){};
  }
}

// var browserVersion= (function(){
//   var ua= navigator.userAgent, tem, 
//   M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
//   if(/trident/i.test(M[1])){
//       tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
//       return 'IE '+(tem[1] || '');
//   }
//   if(M[1]=== 'Chrome'){
//       tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
//       if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
//   }
//   M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
//   if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
//   return M
// })();
// alert(browserVersion[0] + browserVersion[1]);
// if(browserVersion[0])
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

