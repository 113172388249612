import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LinkOrganicaResponse } from '../../../models/LinkOrganicaResponse';
import { LinkOrganica } from '../../../models/LinkOrganica';
import { MainService } from '../../../services/main.service';


@Component({
  selector: 'sitios-interes',
  templateUrl: './sitios-interes.component.html',
  styleUrls: ['./sitios-interes.component.css']
})
export class SitiosInteresComponent implements OnInit {
  @Output() doClick: EventEmitter<any> = new EventEmitter;

  public linksOrganica: LinkOrganicaResponse[];
  public data;
  public pageSize: number = 10;
  public pageNumber: number = 1;
  public loaded: boolean = false;



  constructor(public dataService: DataService, private route: ActivatedRoute,public router: Router, public mainService: MainService) {

  }
  clickLink(link: LinkOrganica) {
    this.mainService.linkMenu(link.urlInterno, link.urlExterno);
  }
  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loaded = false;
    let body = {};
    this.dataService.httpFunction(this.dataService.URL_WEB_LINKS_ORGANICA, this, body, "");
  }

  getClass(link: LinkOrganicaResponse){
    if(link.linkChildren.length == 0){
      return 'espacio';
    }
    if(link.estado == 0){
      return 'fa fa-chevron-down';
    }else{
      return 'fa fa-chevron-up';
    }
  }

  getClassChild(link: LinkOrganica){
    var clase: string = "p-footer link";
    if(link.urlExterno) {
      if(link.urlExterno.length > 0){
        clase += ' link-micrositio';
      }
    }
    return clase;
  }


  clickEvent(link: LinkOrganicaResponse){
    if(link.linkChildren.length == 0){
      return;
    }
    link.estado = 1 - link.estado;

  }


  procesarData(Links: LinkOrganica[]): LinkOrganicaResponse[] {
    let lista: LinkOrganicaResponse[] = [];
    for (let l of Links) {
      if (l.principal == 1) {
        let linkOrganicaResponse: LinkOrganicaResponse = new LinkOrganicaResponse();
        linkOrganicaResponse.linkParent = l;
        linkOrganicaResponse.linkChildren = [];
        lista.push(linkOrganicaResponse);
      }
    }
    for (let l of lista) {
      for (let x of Links) {
        if (x.principal == 0 && x.idLinkOrganicaParent == l.linkParent.id) {
          l.linkChildren.push(x);
        }
      }
    }
    return lista;
  }

  responseOk(httpOperation: string, http: string, data: any, ws: any) {
    //Procesar-Data
    switch (ws.name) {
      case this.dataService.URL_WEB_LINKS_ORGANICA:
        this.data = data;
        this.linksOrganica = this.procesarData(data.content);
        console.error(data.content);
        console.error(this.linksOrganica);
        this.loaded = true;
        break;


    }
  }
  responseError(httpOperation: string, http: string, data: any, ws: any) {
    // alert("E");
    this.loaded =true;
  }

}
