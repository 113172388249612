import { Component, Input, Output,EventEmitter, OnChanges, SimpleChanges  } from '@angular/core';
import { NgModel } from '@angular/forms';


@Component({
  selector: 'input-busqueda',
  templateUrl: './input-busqueda.component.html',
  styleUrls: ['./input-busqueda.component.css']
})

/**
 * Es el componente que sirve para ingresar las pálabras a buscar.
 * Se comunica con la página principal, con el evento para enviar la búsqueda
 */
export class InputBusquedaComponent implements OnChanges{

  /******************************************************************************************************
   * Inputs && Outputs
  ******************************************************************************************************/
  @Input() termino : string;
  @Output() nuevoTerminoEvent = new EventEmitter<string>();

  /******************************************************************************************************
   * Variables
  ******************************************************************************************************/
  term : string = '';
  sutmited : boolean = false;

  /******************************************************************************************************
   * Constructor && OnInit
  ******************************************************************************************************/
  constructor() {

  }

  ngOnInit(){
    this.term = this.termino;
  }

  /******************************************************************************************************
   * Funciones
  ******************************************************************************************************/

  ngOnChanges(changes : SimpleChanges) {
    this.term = changes['termino'].currentValue;
    this.sutmited = false;
  }

  search(){
    if(this.term && (this.term.trim().length >= 3 && this.term.trim().length <= 50) && this.term.trim()!== this.termino){
      this.nuevoTerminoEvent.emit(this.term);
      this.termino = this.term;

    }
    this.sutmited = true;
  }

  searchIntro(e: KeyboardEvent){
    if (e.key != 'Enter') {
      return;
    }
    this.search();
  }


  reset(){
    this.term = null;
    this.nuevoTerminoEvent.emit(this.term);
    this.termino = this.term;
  }
}
