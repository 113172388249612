import { Component, OnInit,Input,Output,EventEmitter, Injectable, OnChanges, SimpleChanges, ViewChild  } from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { Filtro } from 'src/app/models/Filtro';


@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? (date.day < 10 ? '0'+ date.day : date.day) +
     this.DELIMITER + (date.month < 10 ? '0'+ date.month : date.month) +
     this.DELIMITER + date.year : '';
  }
}


@Component({
  selector: 'filtros-especiales-busqueda',
  templateUrl: './filtros-especiales-busqueda.component.html',
  styleUrls: ['./filtros-especiales-busqueda.component.css'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})

/**
 * Muestra los filtros especiales y permite seleccionar alguno
 */
export class FiltrosEspecialesComponent implements OnChanges{

  @Output() aplicarFiltroEvent = new EventEmitter<Filtro>();
  @Input() filtros : Filtro [] = [];

  desde: NgbDateStruct;
  hasta: NgbDateStruct;
  disableDesde : boolean = false;
  disableHasta : boolean = false;
  desdeInvalido : boolean = false;
  hastaInvalido : boolean = false;
  desdeMenor : boolean = false;
  hastaMayor : boolean = false;
  desdeHasta : boolean = false;

  constructor(public formatter : NgbDateParserFormatter) {

  }

  ngOnChanges(changes : SimpleChanges) {
    this.desde = undefined;
    this.hasta = undefined;
    let filtros : Filtro []  = changes.filtros.currentValue.filter(fil => {
      return fil.tipo === 'fecha';
    });
    this.disableDesde  = false;
    this.disableHasta  = false;
    this.hastaMayor = false;
    this.desdeMenor = false;
    this.desdeHasta = false;

    filtros.forEach(fil =>{
      if(fil.operador === '~'){
        let fechas = fil.id.split('~');
        let matchDesde = fechas[0].match(/^([0-9]{1,2})([0-9]{1,2})([0-9]{4})$/);
        let desdeTep = `${matchDesde[1]}/${matchDesde[2]}/${matchDesde[3]}`
        this.desde = this.formatter.parse(desdeTep);

        let matchHasta = fechas[1].match(/^([0-9]{1,2})([0-9]{1,2})([0-9]{4})$/);
        let hastasTep = `${matchHasta[1]}/${matchHasta[2]}/${matchHasta[3]}`
        this.hasta = this.formatter.parse(hastasTep);

        this.disableDesde = true;
        this.disableHasta = true;
      }
      if(fil.operador === '>'){
        let matchDesde = fil.id.match(/^([0-9]{1,2})([0-9]{1,2})([0-9]{4})$/);
        let desdeTep = `${matchDesde[1]}/${matchDesde[2]}/${matchDesde[3]}`
        this.desde = this.formatter.parse(desdeTep);

        this.disableDesde = true;
      }
      if(fil.operador === '<'){
        let matchHasta = fil.id.match(/^([0-9]{1,2})([0-9]{1,2})([0-9]{4})$/);
        let hastasTep = `${matchHasta[1]}/${matchHasta[2]}/${matchHasta[3]}`
        this.hasta = this.formatter.parse(hastasTep);
        this.disableHasta = true;
      }
    });



  }

  submit(){

    let desde = this.formatter.format(this.desde);
    let hasta = this.formatter.format(this.hasta);
    let matchDesde = desde.match(/^([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{4})$/);
    let matchHasta = hasta.match(/^([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{4})$/);
    this.hastaInvalido=false;
    this.desdeInvalido=false;
    this.hastaMayor = false;
    this.desdeMenor = false;
    this.desdeHasta = false;


    if (desde) {
      if(!matchDesde ){
        this.desdeInvalido=true;
        return;
      }
      let desdeDate = new Date(this.desde.year, this.desde.month -1, this.desde.day);
      if(desdeDate <= new Date(1999, 0, 1)){
        this.desdeMenor=true;
        return;
      }
    }


    if(hasta) {
      if(!matchHasta) {
        this.hastaInvalido=true;
        return;
      }
      let hastaDate = new Date(this.hasta.year, this.hasta.month - 1, this.hasta.day);
      if(hastaDate > new Date()){
        this.hastaMayor=true;
        return;
      }
    }

    if(desde && hasta){
      let hastaDate = new Date(this.hasta.year, this.hasta.month - 1, this.hasta.day);
      let desdeDate = new Date(this.desde.year, this.desde.month - 1, this.desde.day);
      if(desdeDate > hastaDate){
        this.desdeHasta=true;
        return;
      }
    }

    let titulo : string;
    let valor : string;
    let tipo = 'fecha';
    let operador : string;
    if(desde && hasta && !(this.disableDesde || this.disableHasta)){
      titulo = `${desde} a ${hasta}`;
      operador = '~';
      valor = `${desde}~${hasta}`;
      this.aplicarFiltroEvent.emit(new Filtro(valor, titulo, tipo, 0, operador));
    } else if(desde && !this.disableDesde){
      operador = '>';
      titulo = `Desde ${desde}`;
      valor = desde;
      this.aplicarFiltroEvent.emit(new Filtro(valor, titulo, tipo, 0, operador));
    } else if(hasta && !this.disableHasta) {
      titulo = `Hasta ${hasta}`;
      operador = '<';
      valor = hasta;
      this.aplicarFiltroEvent.emit(new Filtro(valor, titulo, tipo, 0, operador));
    }
  }

}
