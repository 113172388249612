import { Observable } from "rxjs/Observable";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { Component, OnInit, Input } from "@angular/core";
import { Inject } from "@angular/core";
import { AvisoModel } from "../../../models/AvisoModel";
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from "@angular/platform-browser";
import { DataService } from "src/app/services/data.service";
import { Location } from "@angular/common";

@Component({
  selector: "home-avisos",
  templateUrl: "./avisos.component.html",
  styleUrls: ["./avisos.component.css"],
})
export class AvisosComponent implements OnInit {
  @Input() interes: number = 0;
  @Input() idDependencia: number = -1;

  public data;
  avisos: AvisoModel[] = [];
  public loaded: boolean = false;
  public loading: boolean = false;
  public previousUrl;

  constructor(
    public route: ActivatedRoute,
    public _sanitizer: DomSanitizer,
    public router: Router,
    public dataService: DataService
  ) {}

  getBackgroundUrl(url: string) {
    return this._sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }

  loadAvisos() {
    this.loaded = false;
    this.loading = true;
    let body = {
      nombre: "",
      page: 0,
      size: 3,
      interes: this.interes,
      idDependencia: this.idDependencia,
    };
    this.dataService.httpFunction(
      this.dataService.URL_FIND_AVISO,
      this,
      body,
      ""
    );
  }

  ngOnInit() {
    this.loadAvisos();
  }

  responseOk(httpOperation: string, http: string, data: any, ws: any) {
    //Procesar-Data
    this.loading = false;
    switch (ws.name) {
      case this.dataService.URL_FIND_AVISO:
        this.data = data;
        this.avisos = data.content;
        this.loaded = true;
        console.log(this.avisos);
        break;
    }
  }
  responseError(httpOperation: string, http: string, data: any, ws: any) {
    this.loaded = true;
  }

  verMas() {
    let idDependencia: string = "";
    idDependencia = this.idDependencia.toString();

    if (idDependencia == "-1") {
      idDependencia = "todos";
    }

    this.router.navigate(["/avisos", idDependencia]);
  }
  public getClassName(index: number) {
    let rest = index % 3;
    switch (rest) {
      case 0:
        return "card-azul";
      case 1:
        return "card-amarillo";
      case 2:
        return "card-rojo";
    }
  }

  getData(object: AvisoModel) {
    if (object.resumen != undefined && object.resumen.trim().length > 0) {
      return object.resumen;
    } else {
      return object.cuerpo;
    }
  }
}
