
export class AbstractMock{

    getParameter(key:string, parameters: any){
        let id;
        for(let parameter of parameters){
          if (parameter.key.toLowerCase()== key.toLowerCase()){
            return parameter.value;
          }
        }
        return null;
    }
    
}