import { Component, OnInit } from '@angular/core';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'main-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavBarComponent  implements OnInit {
  title = 'frontend';
  linkPjn: string; 


  public ngOnInit(){
    this.linkPjn = this.appConfig.getConfig("linkPjn", "");  
  }

  public constructor(private appConfig: AppConfig){
    
  }

  
}
