import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { Dependencia } from '../../../models/Dependencia';
import { MainService } from '../../../services/main.service';

@Component({
  selector: 'app-breadcrumb-dependencia',
  templateUrl: './breadcrumb-dependencia.component.html',
  styleUrls: ['./breadcrumb-dependencia.component.css']
})
export class BreadcrumbDependenciaComponent implements OnInit {
  @Input() dependencias: Dependencia[] = [];
  @Output() doClick:EventEmitter<any> = new EventEmitter;

  public _menu: number = 0 ;
  ngOnInit(){

  }
  constructor(private mainService: MainService) { }

  volver(){
    this.mainService.linkMenu("/home","");
  }

}
