import { Component, OnInit, EventEmitter, Output, Version } from '@angular/core';
import { VERSION } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { MainService } from 'src/app/services/main.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-footer-post',
  templateUrl: './footer-post.component.html',
  styleUrls: ['./footer-post.component.css']
})
export class FooterPostComponent implements OnInit {
  @Output() doClick: EventEmitter<any> = new EventEmitter;
  private currentUrl: string = '';
  private esPaginaContacto: boolean = false; 
  

  constructor(public mainService: MainService, private router: Router) { }

  ngOnInit() {
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: any) => {
      this.currentUrl = event.url;
      this.esPaginaContacto = event.url.endsWith('/contacto');
    });


  }


  clickContacto() {
    this.mainService.linkMenu("/pages/contacto", "");

  }
}
 